import { Environment } from 'goodmaps-sdk';

const config = {
  NAME: 'Internal',
  TRACKING_ID: '',
  ENV_ID: Environment.Dev,
  userPoolId: 'us-east-2_SlfRyZYLJ',
  userPoolWebClientId: 'ajbp2i5di9g3bjg597u4bqeim',
  immersalCpsEndpoint: 'https://dev.api.goodmaps.io/servicesv2-services/cpsi',
  immersalAzureEndpoint: 'https://dev-services.goodmapsaz.io/cpsi',
  region: 'us-east-2',
  V1_API: 'https://dev.api.goodmaps.io/servicesv2-services',
  AZURE_API: 'https://api.dev.goodmapsaz.io',
  AWS_API: 'https://web-api.us-east.goodmaps.io',
  schemaVersion: '2',
};

export default config;
